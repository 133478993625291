import { SectionContext } from './SectionContext';
import { useState } from 'react';

interface Props {
  children: React.ReactNode;
}

export const SectionProvider = ({ children }: Props) => {
  const [activeSection, setActiveSection] = useState<string>('home-section');

  const value = {
    activeSection,
    setActiveSection,
  };

  return <SectionContext.Provider value={value}>{children}</SectionContext.Provider>;
};
