import { useEffect } from 'react';
import { useRouter } from 'next/router';

const UrlChangePicker = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      document.body.style.overflow = 'scroll';
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('hashChangeStart', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('hashChangeStart', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, []);

  return null;
};

export default UrlChangePicker;
