import '../styles/globals.scss';

import { useEffect } from 'react';

import Layout from '../components/Layout';
import UrlChangePicker from '../components/UrlChangePicker/UrlChangePicker';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function MyApp({ Component, pageProps }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      // GTM script
      const script = document.createElement('script');
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WMT3GV5');
    `;
      document.head.appendChild(script);

      // GTM noscript fallback
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-WMT3GV5';
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);
      document.body.insertBefore(noscript, document.body.firstChild);

      console.log('GTM script loaded');
    }, 5000); // Delay in milliseconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout>
      <UrlChangePicker />
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;
