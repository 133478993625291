import { createContext } from 'react';

export type SectionContextType = {
  activeSection: string;
  setActiveSection: (section: string) => void;
};

export const SectionContext = createContext<SectionContextType>({
  activeSection: 'home-section',
  setActiveSection: () => {
    return 'home-section';
  },
});
