import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Header from './Header';
import { SectionProvider } from './SectionContext/SectionProvider';
const Scripts = dynamic(() => import('./Scripts'));
import React from 'react';

import layoutDataEl from '../content/global/el/index.json';
import layoutDataEn from '../content/global/en/index.json';

const NavBarComponent = dynamic(() => import('./NavBar/NavBar'), {
  loading: () => <p>Loading...</p>,
});
const FooterComponent = dynamic(() => import('./Footer/Footer'), {
  loading: () => <p>Loading...</p>,
});

const Layout = (props) => {
  const router = useRouter();
  console.log(router);
  const { locale } = router;
  const data = locale === 'el' ? layoutDataEl : layoutDataEn;
  //Simple layout to encapsulate the app
  return (
    <div className="flex overflow-scroll">
      <Header />
      <Scripts />
      <SectionProvider>
        {router.pathname !== '/m/[marriage]' && <NavBarComponent t={data.navigation} />}
        <div>{props.children}</div>
        {router.pathname !== '/m/[marriage]' && <FooterComponent t={data.footer} />}
      </SectionProvider>
    </div>
  );
};
export default Layout;
